import React from 'react';
import Card from '@material-ui/core/Card';
import { makeStyles, CardContent, CardMedia, Typography, CardActionArea, Divider, 
    List, ListItem, ListItemIcon, ListItemText, Grid, Paper } from '@material-ui/core';
import StarBorder from '@material-ui/icons/StarBorder';


const useStyles = makeStyles({
    root: {
      minWidth: 275,
      margin: 40
    },
    media: {
        height: 140,
    },
    headerText: {
        fontSize:40
    },
    subHeaderText: {
      fontSize:26
    },
    paper: {
      padding: 10,
      textAlign: 'center',
    },
    image: {
      //maxHeight:200,
      margin: 10,
      height: 160
    }
})


const Home = () => {
    const classes = useStyles();

    return (
<Card className={classes.root}>
    <CardActionArea>
        {/* <CardMedia
          className={classes.media}
          image="https://cultivatingwomensleadership.org/wp-content/uploads/2013/10/elsa-headshot.jpg"
          title="Elsa"
        /> */}
        <Typography className={classes.headerText}>
            ElsaMenendez.com
        </Typography>
        <Typography className={classes.subHeaderText}>
            Where amazing meets awesome.
        </Typography>
        <Divider></Divider>
        <Typography className={classes.subHeaderText}>
        Experienced wicked smart facilitator of all things!
        </Typography>


    <CardContent>
        <Grid container spacing={3}>
        <Grid item xs={6}>
          <Paper className={classes.paper}>

    <List component="div" disablePadding>
        <ListItem >
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary="Field Representative for US Senator Martin Heinrich" />
          </ListItem>
    <ListItem >
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary="Leader at Cultivating Women's Leadership" />
          </ListItem>
          <ListItem >
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary="Founder and Director - Circo Latino and 
            Circo Radical Summer Youth Institute" />
          </ListItem>
    

          <ListItem >
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary="Writer, director, producer and performer with Tricklock Theater" />
          </ListItem>
          <ListItem >
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary="Director of Education and Outreach Coordinator at the National Hispanic Cultural Center" />
          </ListItem>
          <ListItem >
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary="Program Manager and Associate Artistic Director at at Oddfellows Playhouse Youth Theatre" />
          </ListItem>
          <ListItem >
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary="Associate producer in London for BBC movie shorts" />
          </ListItem>
          <ListItem >
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary="Co-founder of BOOM!theatre, 
              a theatre company within the prison comprised of individuals serving the longest sentence" />
          </ListItem>
          {/* <ListItem >
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary="co-founder of BOOM!theatre, 
              a theatre company within the prison comprised of individuals serving the longest sentence" />
          </ListItem>
           */}
        </List>
      </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <img className={classes.image} src='images/Circo.jpg'/>
            <img className={classes.image} src='images/elsa-tangerines.jpg'/>
            <img className={classes.image} src='images/elsa-headshot.jpg'/>
            <img className={classes.image} src='images/elsa-tricklock.jpg'/>
            <img className={classes.image} src='images/elsa-navelorange.jpg'/>
            <img className={classes.image} src='images/elsa-eva.jpg'/>
            <img className={classes.image} src='images/circo2.jpg'/>
            
            
            </Paper>
        </Grid>
        </Grid>
        <Divider></Divider>
        <Typography className={classes.headerText}>
        Happy Birthday, Elsa!  You are surprising and amazing and I am so lucky!
        </Typography>

    </CardContent>
    </CardActionArea>
</Card>
    )
}

export default Home;